import { render, staticRenderFns } from "./SetCommission.vue?vue&type=template&id=e336ca06&scoped=true"
import script from "./SetCommission.vue?vue&type=script&lang=js"
export * from "./SetCommission.vue?vue&type=script&lang=js"
import style0 from "./SetCommission.vue?vue&type=style&index=0&id=e336ca06&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e336ca06",
  null
  
)

export default component.exports