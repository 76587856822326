<template>
    <div class="set-commission-info">
       <div class="set-commission-info__title">{{ $t('commission.title') }}</div> 
       <div v-if="companyInfo" class="set-commission-info__body">
          <div class="set-commission-info__account-name">{{ $t('commission.add_account')}}</div>
          <div>{{ companyInfo.accountName }}</div>
          <div class="set-commission-info__account-name">{{ $t('commission.commission')}}</div>
       </div>
       <div
            class="form-input set-commission-info__popup-input"
            :class="
          [
            {'form-input--full' : commission},
            {'form-input--error' : errors.commission || (serverErrors && serverErrors.value)}
          ]"
        >
          <input v-model="commission" type="text" :maxlength="4">
          <div v-if="errors.commission" class="form-input__error" v-text="errors.commission" />
          <span class="form-input__label">
            %
          </span>
        </div>
        <div v-if="serverErrors && serverErrors.value" class="form-input__error">
          <p v-for="(err, index) in serverErrors.value" :key="index">
            {{ err }}
          </p>
        </div>
        <button class="set-commission-info__popup-btn" :disabled="isDisabled" @click="sendCommission">{{$t('button_props.save')}}</button>
    </div>
  </template>
  
  <script>
  import numberWithSpacesCeil from '@/mixin/numberWithSpaces'
  import numberWithSpacesFixed from '@/mixin/numberWithSpaces'
  // import axios from 'axios'

  // const apiUrl = process.env.VUE_APP_API_URL
  
  export default {
    name: 'SetCommission',
    mixins: [numberWithSpacesCeil, numberWithSpacesFixed],
    data() {
      return {
        commission: null,
        errors: {
          'commission': null
        },
      
        isError: false
      }
    },
    computed: {
      companyInfo () {
        return this.$store.state.Company.companyData
      },
      activeAccount() {
        return this.$store.state.Auth.activeAccount
      },
      isDisabled() {
        let error = false;
        if (this.errors && this.errors.commission) {
          error = true
        }
        return error
      },
      serverErrors() {
        return this.$store.state.SetCommissions.serverErrors
      }
      
    },
    watch: {
      commission: {
        deep: true,
        handler(val) {
          if(val) {
            this.errors.commission = null
            this.isError = false
          }
        }
      }
    },
    mounted(){
      if(this.$store.state.Company.companyData.commission) {
        this.commission = this.$store.state.Company.companyData.commission
      }
    },
    methods: {
      cancel() {
        this.$store.dispatch('Dialog/closeDialog')
      },
      validateForm() {
          if (this.commission < 0 || this.commission >= 100 || !this.commission) {
              this.errors.commission = this.commission < 0 || this.commission >= 100 ? this.$t('commission.error_positive') : null
              this.isError = this.commission < 0 || this.commission >= 100
          }
      },
      sendCommission() {
        this.validateForm()
        if (this.isError) {
          return false
        }
        const token = localStorage.getItem('token')
        const id = this.companyInfo.accountId
        const data = {
          commission: this.commission,
          id,
          token
        }
        this.$store.dispatch('SetCommissions/sendCommission', data)
        .then(()=> {
          this.$store.dispatch('Dialog/closeDialog')
        })
        .catch((err) => {
          console.error(err)
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";
    .set-commission-info {
        color:  #333;
        font-family: 'Roboto';
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
      &__title {
        font-family: 'Roboto';
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #333;
        max-width: 250px;
      }
      padding-top: 1rem;
  
      &__account-name {
        font-family: 'Roboto';
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $basicBlack;
      }

      &__popup-input {
        width: 30%;
        margin: 0;
      }

      &__popup-btn {
        outline: none;
        font-size: 1.6rem;
        font-weight: 700;
        font-family: 'Roboto';
        line-height: 1.9rem;
        color: #fff;
        border: 1px solid var(--primary);
        border-radius: 4px;
        background: var(--primary);
        transition: background-color .4s, color .4s;
        padding: 13px;
        width: 100%;
        margin-top: 1rem;
        &:disabled {
          background: #5a5858;
          border: 1px solid #5a5858;
        }
      }
      
      &__account-name:first-child {
        margin-bottom: 1rem;
      }
      &__account-name:last-child {
        margin-top: 1.5rem;
      }
    }
  </style>
  